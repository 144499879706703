var proxy_base_url = '/_services/gemm-proxy/';

// Set to debug mode when serving from localhost
var DEBUGGING = window.location.hostname === "localhost" || (window.location.href.indexOf('debug=1') > -1);


var isFormValid = true;

function setFormError($field, error_msg) {
    $field.parent().addClass('sq-form-question-error');
    $field.addClass('sq-form-field');
    $field.parent().after('<div class="inline-field"><label class="input-label"> </label><p for="'+$field.attr('id')+'" class="input-control sq-form-error">'+error_msg+'</p></div>');
    isFormValid = false;
}

function getFormData(id, isGEMMSelect){
    // get val
    var $field = $('#'+id);

    var value = $field.val();

    if($field.prop('required')){
        if( !value ) {
            setFormError($field, 'This field is required.');
            return null;
        }
    }

    // if( type is checkbox)
    // get if it's checked as boolean
    // }

    if($field.prop('type') == 'checkbox'){
        value = $.parseJSON($field.is(':checked'));
    }


    // if isGEMMSelect
    // return json {Label:"", Val:""}
    if( isGEMMSelect ){
        value = {
            "Label": $('input[name="'+id+'_Label"]').val(),
            "Val":   $field.val()
        }
    }


    // cast string "true" "false" into boolean values
    if(value === 'true'){
        value = true;
    }
    if(value === 'false'){
        value = false;
    }
    return value;
}

function getContactsOptionSetData(){
    if(DEBUGGING) {
        return $.when( DevData.myProfile.optionSets );
    }
    return $.ajax({
            url: proxy_base_url+'getcontactoptionset',
            method: 'get',
            crossDomain: true,
            timeout: {{api_timeout}}
        });
}


/**
 * make http call to get contact data
 */
function getContactData(){
    // if debug
    if ( DEBUGGING ) {
        return $.when( DevData.myProfile.contactData );
    }


    return  $.ajax({
                url : proxy_base_url + 'getcontact', // GetContact Proxy ./?a=11260
                method : 'get',
                crossDomain: true,
                timeout: {{api_timeout}}
            })
            .fail(function(error){
                $('.loading-overlay').hide();
                swal("Error", "Cannot load member details", "error");
                console.log('or maybe here');
                console.log(JSON.stringify(error));
            });
}


function loadGEMMSelectFields(optionSetArray){
    return new Promise( function (resolve) {
        for(var name in optionSetArray){

            var optionSet = optionSetArray[name];
            var optionsHtml = '';

            switch( name ) {
                case 'OpeningTimes':
                case 'ClosingTimes':
                case 'Title':
                case 'Suffix':
                case 'Awards':
                // add null option
                    optionsHtml += '<option value=""></option>'
                    break;
            }
            for(var i = 0 ; i < optionSet.length; i++){
                var option = optionSet[i];
                optionsHtml += '<option value="'+option.Val+'">'+option.Label+'</option>';
            }
            // osk stands for OptionSet Key
            // data-osk has priority
            $('select').each(function(){

                if($(this).data('osk') == name) {
                    $(this).html(optionsHtml);
                    return;
                }

                if($(this).attr('name') == name){
                    $(this).html(optionsHtml);
                }
            })
        }

        resolve();

    });
}

/**
 * load xxx_Label field to fulfill the CRM API requirement on enum fields
 * @param  string   key field name
 */
function loadSelectFieldLabel(key, label_text, available_options){
    var label_field_name = key+'_Label';

    var $label_field = $('input[name='+label_field_name+']');
    $label_field.val(label_text);

    $('select[name='+key+']').on('change', function(){
        var selected_value = $(this).val();
        if(selected_value == ''){
            $label_field.val('');
            return;
        }
        for(var i = 0; i < available_options.length; i++){
            if(available_options[i].Val == selected_value ){
                $label_field.val(available_options[i].Label);
                break;
            }
        }
    });

}


function copyAddress($checkbox, address_fields_id_mapping){
    var checked = $checkbox.is(':checked');
    for( var from_field_id in address_fields_id_mapping ){
        var to_field_id = address_fields_id_mapping[from_field_id];

        if(checked){
            $('#'+to_field_id).val($('#'+from_field_id).val());
            $('#'+to_field_id).attr('readonly', true);
        }else{
            $('#'+to_field_id).attr('readonly', false);
        }
    }
};
