(function($){
	$.fn.modal = function (options) {
		// hide original content
		$('#'+this.data('modal')).hide();


		this.click(function(){
			$(this).openModal(options);
		});

	}

	$.fn.closeModal = function(isConfirm) {
		// 1. get data from current modal
		var formData = {};
		if(isConfirm){
			
			formData = $('.modal-wrapper').find('form').serializeObject();

		}

		// 2. remove modal__wrapper
		if($('.modal-wrapper').length > 0){
			$('.modal-wrapper').remove();
		}

		// 3. return promise with data
		return new Promise(function(resolve){
			resolve(formData);
		})
	}

	/**
	 * options = {
	 * 	confirm : false,
	 * 	cancelBtnText : 'Cancel',
	 * 	confirmBtnText: 'Confirm',
	 * }
	 * @param  {[type]} options [description]
	 * @return {[type]}         [description]
	 */
	$.fn.openModal = function(options) {
		var defaults = {
				confirm:        false,
				cancelBtnText:  'Cancel',
				confirmBtnText: 'Confirm',
				onCreate:       function(){},
				onClose:        function(){},
				onConfirm:      function(data){}
			};

		options = $.extend({}, defaults, options || {});

		var $this = this;
		var $body = $('body');
		var modalId = $this.data('modal');
		var $modalContent = $('#'+modalId);

		// remvoe all modal-wrapper if there is any
		if($('.modal-wrapper').length > 0){
			$('.modal-wrapper').remove();
		}

		var btnRowHtml = '';
		var contentHtml = $modalContent.html();

		if(options.confirm){
			var cancelBtnText = (options.cancelBtnText) ? options.cancelBtnText : 'Cancel';
			var confirmBtnText = (options.confirmBtnText) ? options.confirmBtnText : 'Confirm';
			btnRowHtml = '\
						<div class="btn-row">\
							<button type="button" class="button round-all button--outline modal__cancel-btn">'+cancelBtnText+'</button>\
							<button type="button" class="button round-all modal__confirm-btn">'+confirmBtnText+'</button>\
						</div>';
		}
		var template = '\
			<div class="modal-wrapper content-container">\
				<div class="modal__bg"></div>\
				<div class="modal__wrap">\
					<div class="modal__container">\
						<div class="modal__content">\
							<a class="modal__close">\
								<i class="icon-close"></i>\
								<span class="visuallyhidden">Close</span>\
							</a>\
							<form>\
							'+contentHtml+'\
							</form>\
							'+btnRowHtml+'\
						</div>\
					</div>\
				</div>\
			</div>\
		';

		$body.prepend(template);

		if($('.modal__content').height() > $(window).height() && $(window).width() < 568){
			$('.modal__wrap').css({
				'top': '10px', 
				'transform': 'translate(-50%, 0%)',
			});

			$('.modal__content').css({
				'max-height': $(window).height() - 20
			});
		}

		options.onCreate();

		// close modal
		$('.modal__bg, .modal__close, .modal__cancel-btn').click(function(){
			$this.closeModal()
			options.onClose();
		});

		// confirm modal
		$('.modal__confirm-btn').click(function(){
			$this.closeModal(true).then(function(data){
				options.onConfirm(data);
			});
		});

	};
}(jQuery));